import { prefix } from '../constants/application';

const viewer = () => {
  const viewerDocument = document.querySelector(`.js-${prefix}-viewer-document`);
  const viewerTabs = document.querySelectorAll(`.js-${prefix}-viewer-tab`);

  if (!viewerDocument || !viewerTabs.length) return;

  Array.prototype.forEach.call(viewerTabs, (tab) => {
    tab.addEventListener('click', () => {
      viewerDocument.src = tab.dataset.src;
    });
  });

};

export default viewer;
