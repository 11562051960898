// TODO: handle import routes with babel
import nav from './components/nav';
import form from './components/form';
import viewer from './components/viewer';

document.addEventListener('DOMContentLoaded', () => {
  // DOM loaded
  form();
});

window.addEventListener('load', () => {
  nav();
  viewer()
  // All resources loaded
});
